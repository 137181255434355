import * as React from 'react'
import { Seo } from 'components/helpers'
import { DefaultLayout } from 'layouts'
import HomeView from 'views/home'

const IndexPage: React.FC = () => (
  <DefaultLayout>
    <Seo
      title="Superlógica"
      description="Se você deseja transformar o mundo, com toda certeza o seu lugar é ao nosso lado. As nossas vagas são para todas as pessoas! Venha ser um superlógico."
    />
    <HomeView />
  </DefaultLayout>
)

export default IndexPage
