import React from 'react'

import SectionIntro from './Section.Intro'
import SectionRibbon from './Section.Ribbon'
import SectionAreas from './Section.Areas'
import SectionDiversity from './Section.Diversity'
import SectionTestimonies from './Section.Testimonies'
import SectionBenefits from './Section.Benefits'
import SectionTech from './Section.Tech'
import SectionCta from './Section.Cta'
import SectionFaq from './Section.Faq'

const HomeView = () => (
  <>
    <SectionIntro />
    <SectionRibbon />
    <SectionAreas />
    <SectionDiversity />
    <SectionTestimonies />
    <SectionBenefits />
    <SectionTech />
    <SectionCta />
    <SectionFaq />
  </>
)

export default HomeView
