import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title, Text, Carousel } from '@superlogica/design-ui'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Testimony } from 'components/app'

import * as S from './View.styled'

interface TestimoniesDataProps {
  allTestimoniesJson: {
    nodes: {
      category: string
      body: string
      author: {
        name: string
        role: string
        avatar: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
          }
        }
      }
    }[]
  }
}

const SectionTestimonies = () => {
  const {
    allTestimoniesJson: { nodes: testimonies }
  } = useStaticQuery<TestimoniesDataProps>(
    graphql`
      query {
        allTestimoniesJson(filter: { category: { eq: "home" } }) {
          nodes {
            category
            body
            author {
              name
              role
              avatar {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <S.SectionTestimonies>
      <Container>
        <Block tag="header" marginBottom={{ xxxs: 32, md: 60 }}>
          <Title
            color="babar900"
            font="heading"
            weight="medium"
            size="display03"
            secondColor="sonic500"
            marginBottom={16}
          >
            Nós somos a <span>Superlógica</span>
          </Title>
          <Text color="babar900" size="body03" weight="medium" maxWidth={570}>
            Respeitamos as diferenças e abrimos espaço para que as pessoas
            possam ser quem elas realmente são sem medo.
          </Text>
        </Block>
        <Block tag="article">
          <Carousel
            fullMobile
            bulletColor="sonic500"
            activeBulletColor="sonic500"
            swiper={{
              loop: true,
              initialSlide: 0,
              autoplay: true,
              observer: true,
              spaceBetween: 64,
              watchOverflow: true,
              simulateTouch: false,
              centeredSlides: false,
              centerInsufficientSlides: true,
              pagination: {
                clickable: true
              },
              slidesPerView: 2,
              breakpoints: {
                0: {
                  slidesPerView: 1
                },
                820: {
                  slidesPerView: 2
                }
              }
            }}
          >
            {testimonies.map(({ author, body }) => (
              <Testimony
                key={author.name}
                body={body}
                author={author}
                image={
                  <GatsbyImage
                    image={author.avatar.childImageSharp.gatsbyImageData}
                    alt={author.name}
                    objectFit="cover"
                  />
                }
              />
            ))}
          </Carousel>
        </Block>
      </Container>
    </S.SectionTestimonies>
  )
}

export default SectionTestimonies
