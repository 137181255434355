import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Block, Title, Text } from '@superlogica/design-ui'
import { Container } from 'react-awesome-styled-grid'
import { Faq } from 'components/app'

interface FaqData {
  allFaqJson: {
    nodes: {
      title: string
      body: string
    }[]
  }
}

const SectionFaq = () => {
  const {
    allFaqJson: { nodes: faq }
  } = useStaticQuery<FaqData>(graphql`
    {
      allFaqJson(filter: { area: { eq: "home" } }) {
        nodes {
          title
          body
        }
      }
    }
  `)

  return (
    <Block
      id="faq"
      tag="section"
      paddingTop={{ xxs: 60, md: 80 }}
      paddingBottom={{ xxs: 60, md: 120 }}
    >
      <Container>
        <Block tag="header" marginBottom={{ xxxs: 24, md: 32 }}>
          <Title color="babar900" font="heading" size="display03">
            Perguntas frequentes
          </Title>
        </Block>
        <Block tag="article">
          <Faq items={faq} />
        </Block>
      </Container>
    </Block>
  )
}

export default SectionFaq
