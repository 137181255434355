import React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button, Vector } from '@superlogica/design-ui'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { BadgeTech } from 'components/app'

import * as S from './View.styled'

interface AvatarProps {
  allPhotosJson: {
    nodes: {
      id: string
      order: number
      image: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
    }[]
  }
}

const SectionTech = () => {
  const {
    allPhotosJson: { nodes: avatars }
  } = useStaticQuery<AvatarProps>(
    graphql`
      query {
        allPhotosJson(filter: { category: { eq: "avatar-tech" } }) {
          nodes {
            id
            order
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    `
  )

  return (
    <S.SectionTech id="tecnologia">
      <Block overflow="hidden">
        <Container>
          <Row>
            <Col md={8}>
              <Block
                tag="header"
                paddingTop={{ xxxs: 60, md: 80 }}
                marginBottom={32}
              >
                <Title
                  color="babar0"
                  font="heading"
                  size="display03"
                  secondColor="warning"
                  maxWidth={460}
                  marginBottom={32}
                >
                  <span>Tecnologia</span> que transforma o mundo
                </Title>
                <Text color="babar0" size="body03" marginBottom={16}>
                  Gosta de fintechs? Ama tecnologia e busca inovar e se
                  reinventar sempre? A gente também!
                  <br /> E é por isso que o desenvolvimento é a nossa paixão.
                </Text>
                <Text color="babar0" size="body03" weight="bold">
                  Nosso ambiente oferece produtos digitais, serviços, inovação e
                  muita tecnologia!
                </Text>
              </Block>
              <Block
                tag="section"
                display="flex"
                paddingBottom={{ xxxs: 40, md: 80 }}
                alignItems={{ xxxs: 'flex-start', sm: 'center' }}
                flexDirection={{ xxxs: 'column', sm: 'row' }}
                gap={24}
              >
                <Block display="flex">
                  {avatars.map((item) => (
                    <S.AvatarWrapper key={item.order}>
                      <GatsbyImage
                        alt="Colaboradores Superlógica"
                        image={item.image.childImageSharp.gatsbyImageData}
                        loading="eager"
                        objectFit="cover"
                        objectPosition="center"
                        style={{
                          width: '100%',
                          height: '100%'
                        }}
                      />
                    </S.AvatarWrapper>
                  ))}
                </Block>

                <Button
                  tag="a"
                  href="https://www.inhire.com.br/carreiras-superlogica/"
                  rel="noreferrer noopener"
                  title="Venha ser Super Dev com a gente!"
                  target="_blank"
                  appearance="link"
                  size="body01"
                  color="warning"
                  weight="bold"
                  className="arrow-animation arrow-right-animation btn-tech"
                  rightIcon={<Vector name="arrow-right-animation" />}
                  iconSize={12}
                  uppercase
                >
                  Venha ser Super Dev com a gente!
                </Button>
              </Block>
            </Col>
            <Col md={3} offset={{ md: 1 }}>
              <S.InnerSection>
                <Block
                  height="100%"
                  display="flex"
                  flexDirection={{ xxxs: 'row', sm: 'column' }}
                  alignItems="flex-start"
                  justifyContent={{ xxxs: 'flex-start', sm: 'center' }}
                  gap={12}
                  padding={{ xxxs: '60px 0', md: 0 }}
                  className="list-tech"
                >
                  <BadgeTech color="sonic400" tech="#PHP" />
                  <BadgeTech color="sonic500" tech="#Javascript" />
                  <BadgeTech color="sonic400" tech="#NodeJS" />
                  <BadgeTech color="sonic500" tech="#ReactNative" />
                  <BadgeTech color="sonic400" tech="#CulturaDevOps" />
                  <BadgeTech color="sonic500" tech="#Mobile" />
                </Block>
              </S.InnerSection>
            </Col>
          </Row>
        </Container>
      </Block>
    </S.SectionTech>
  )
}

export default SectionTech
