import * as React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { StaticImage } from 'gatsby-plugin-image'
import { Block, Title, Text, Button } from '@superlogica/design-ui'
import { Social } from 'components/app'
import { Link } from 'components/helpers'

import * as S from './View.styled'

const SectionCta = () => (
  <S.SectionCta>
    <Container>
      <Row>
        <Col md={5}>
          <Block padding={{ xxxs: '60px 0 0', md: '80px 0' }} margin="auto 0">
            <Title
              color="babar0"
              size="overline02"
              letterSpacing={1}
              marginBottom={20}
              weight="bold"
              uppercase
            >
              Banco de Talentos
            </Title>
            <Text
              color="babar0"
              font="heading"
              size="display04"
              weight="light"
              marginBottom={16}
            >
              Ainda não encontrou sua vaga?
              <br /> <strong>Não tem problema!</strong>
            </Text>
            <Text color="babar0" size="body03" marginBottom={32}>
              Faça seu cadastro e deixe o seu currículo em nosso banco de
              talentos. Vai ser super ter você em nosso time!
            </Text>
            <Button
              tag="a"
              href="https://www.inhire.com.br/carreiras-superlogica/"
              target="_blank"
              title="Faça seu cadastro"
              color="warning"
              size="md"
              weight="semiBold"
              marginBottom={24}
            >
              Faça seu cadastro
            </Button>
            <Title
              color="babar0"
              size="overline02"
              letterSpacing={1}
              marginBottom={16}
              weight="bold"
              uppercase
            >
              Nos encontre nas redes sociais
            </Title>
            <Social color="babar0" />
          </Block>
        </Col>
        <Col md={6} offset={{ md: 1 }}>
          <S.InnerSection>
            <S.InnerImage>
              <StaticImage
                src="../../images/home/colaboradores-na-superlogica.png"
                alt="Colaboradores na Superlógica"
              />
            </S.InnerImage>
          </S.InnerSection>
        </Col>
      </Row>
    </Container>
  </S.SectionCta>
)

export default SectionCta
