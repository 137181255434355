import { useEffect, useMemo, useState } from 'react'
import { useHarmonicIntervalFn } from 'react-use'
import { GalleryNodeData, GalleryImageData } from 'fragments'
import { groupBy, getNextIndex } from '../utils'

type UseMosaicOptionsProps = {
  interval?: number
}

const useMosaic = (data: GalleryNodeData, opts?: UseMosaicOptionsProps) => {
  const [total, setTotal] = useState<number>(0)
  const [perGroup, setPerGroup] = useState<number>(0)
  const [index, setIndex] = useState<number>(0)
  const [gallery, setGallery] = useState<GalleryImageData[]>([])

  const { allPhotosJson } = data
  const interval = opts?.interval || 1000 * 4

  useEffect(() => {
    const countPhotos = allPhotosJson.nodes.length
    const groups = groupBy(allPhotosJson.nodes, 'order')
    const countGroups = Object.keys(groups).length

    setTotal(countPhotos)
    setPerGroup(countGroups)
    setIndex(countGroups)
  }, [allPhotosJson])

  useEffect(() => {
    const initialGallery = allPhotosJson.nodes.filter((item, i) => i < perGroup)

    setGallery(initialGallery)
  }, [perGroup])

  useHarmonicIntervalFn(() => {
    const prevGallery = gallery
    const photo = allPhotosJson.nodes[index]

    if (photo) {
      const nextOrder = photo.order - 1
      prevGallery[nextOrder] = photo

      const nextIndex = getNextIndex(index, total)
      setIndex(nextIndex)
    }

    setGallery([...prevGallery])
  }, interval)

  const groups = useMemo(() => [...Array(perGroup).keys()], [total])

  return { groups, gallery }
}

export default useMosaic
