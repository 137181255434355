import React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title, Text, Button, Vector } from '@superlogica/design-ui'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { GalleryNodeData } from 'fragments'

import * as S from './View.styled'

const SectionAreas = () => {
  const {
    allPhotosJson: { nodes: avatars }
  } = useStaticQuery<GalleryNodeData>(
    graphql`
      query {
        allPhotosJson(filter: { category: { eq: "avatar-areas" } }) {
          nodes {
            id
            order
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 90
                )
              }
            }
          }
        }
      }
    `
  )

  return (
    <S.SectionAreas>
      <Container>
        <Row>
          <Col md={5}>
            <Block tag="header" marginBottom={{ xxxs: 40, md: 0 }}>
              <Text
                size="label04"
                color="sonic500"
                weight="bold"
                letterSpacing={1}
                marginBottom={24}
                uppercase
              >
                Juntos, conhecemos o caminho
              </Text>
              <Title
                font="heading"
                color="babar900"
                size="display03"
                secondColor="sonic500"
                marginBottom={24}
              >
                Somos pessoas com uma paixão em comum:
                <br />
                <span className="break-title">
                  a vontade de transformar o mundo!
                </span>
              </Title>

              <Block display="flex" alignItems="center">
                {avatars.map((item) => (
                  <S.AvatarWrapper key={item.id}>
                    <GatsbyImage
                      alt="Colaborador Superlógica"
                      image={item.image.childImageSharp.gatsbyImageData}
                      loading="eager"
                      objectFit="cover"
                      objectPosition="center"
                      style={{
                        width: '100%',
                        height: '100%'
                      }}
                    />
                  </S.AvatarWrapper>
                ))}
                <S.AvatarWrapper>
                  <span className="you-circle">Você</span>
                </S.AvatarWrapper>
              </Block>
            </Block>
          </Col>
          <Col md={6} offset={{ md: 1 }}>
            <Block tag="article">
              <Text
                size="label04"
                color="babar900"
                weight="bold"
                letterSpacing={1}
                marginBottom={32}
                uppercase
              >
                Conheça nossas áreas e vagas disponíveis
              </Text>
              <Block
                paddingBottom={24}
                marginBottom={24}
                borderBottom="1px solid"
                borderColor="trooper25"
              >
                <Button
                  tag="a"
                  href="https://www.inhire.com.br/carreiras-superlogica/"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Tecnologia"
                  size="overline02"
                  appearance="link"
                  color="sonic500"
                  weight="bold"
                  className="arrow-animation arrow-right-animation"
                  rightIcon={<Vector name="arrow-right-animation" />}
                  iconSize={12}
                  marginBottom={16}
                  uppercase
                >
                  Tecnologia
                </Button>
                <Text color="trooper900">
                  Criar sistemas disruptivos é o que faz o nosso coração bater
                  mais forte. É assim que transformamos negócios e a maneira de
                  viver e conviver.
                </Text>
              </Block>
              <Block
                paddingBottom={24}
                marginBottom={24}
                borderBottom="1px solid"
                borderColor="trooper25"
              >
                <Button
                  tag="a"
                  href="https://www.inhire.com.br/carreiras-superlogica/"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Comercial"
                  size="overline02"
                  appearance="link"
                  color="sonic600"
                  weight="bold"
                  className="arrow-animation arrow-right-animation"
                  rightIcon={<Vector name="arrow-right-animation" />}
                  iconSize={12}
                  marginBottom={16}
                  uppercase
                >
                  Comercial
                </Button>
                <Text color="trooper900">
                  A nossa área comercial fica na linha de frente dos nossos
                  negócios. Facilitamos decisões e ajudamos empresas a
                  crescerem.
                </Text>
              </Block>
              <Block>
                <Button
                  tag="a"
                  href="https://www.inhire.com.br/carreiras-superlogica/"
                  target="_blank"
                  rel="noreferrer noopener"
                  title="Operacional"
                  size="overline02"
                  appearance="link"
                  color="sonic600"
                  weight="bold"
                  className="arrow-animation arrow-right-animation"
                  rightIcon={<Vector name="arrow-right-animation" />}
                  iconSize={12}
                  marginBottom={16}
                  uppercase
                >
                  Operacional
                </Button>
                <Text color="trooper900">
                  A experiência do cliente é o que nos motiva e nos inspira, por
                  isso, queremos que ela seja incrível e apaixonante.
                </Text>
              </Block>
            </Block>
          </Col>
        </Row>
      </Container>
    </S.SectionAreas>
  )
}

export default SectionAreas
