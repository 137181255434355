import React from 'react'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { Block, Title, Text, Vector } from '@superlogica/design-ui'
import { useHarmonicIntervalFn } from 'react-use'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { GalleryNodeData } from 'fragments'
import { AnimatePresence } from 'framer-motion'
import useMosaic from 'hooks/use-mosaic'

import * as S from './View.styled'

const SectionDiversity = () => {
  const data = useStaticQuery<GalleryNodeData>(graphql`
    query {
      allPhotosJson(
        filter: {
          category: {
            in: [
              "mosaic-diversity-1"
              "mosaic-diversity-2"
              "mosaic-diversity-3"
            ]
          }
        }
      ) {
        nodes {
          ...GalleryData
        }
      }
    }
  `)

  const { groups, gallery } = useMosaic(data, { interval: 1500 })

  return (
    <S.SectionDiversity>
      <Container>
        <Row align="center">
          <Col md={5}>
            <Block
              tag="header"
              maxWidth={{ xxxs: '100%', md: 400 }}
              marginBottom={{ xxxs: 40, md: 0 }}
            >
              <Title
                font="heading"
                color="babar900"
                secondColor="sonic500"
                size="display02"
                marginBottom={16}
                maxWidth={{ xxxs: '100%', md: 300 }}
              >
                Diversidade com <span>Você</span>
              </Title>
              <Text
                color="babar900"
                size="body03"
                marginBottom={16}
                lineHeight={1.4}
              >
                Respeitamos as diferenças e abrimos espaço para que as pessoas
                possam ser quem elas realmente são sem medo.
              </Text>
              <Text color="babar900" size="body03" weight="bold">
                Somos para todos, a diversidade e inclusão são nossos
                compromissos!
              </Text>
            </Block>
          </Col>
          <Col md={7}>
            <S.Mosaic>
              {groups.map((i) => {
                const tile = gallery[i]

                return (
                  <S.SkeletonMosaic key={i}>
                    {!!tile && (
                      <AnimatePresence>
                        <S.MosaicItem
                          key={`tile-${tile.id}`}
                          transition={{ duration: 2 }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {!!tile.image ? (
                            <GatsbyImage
                              alt="Colaborador Superlógica"
                              objectFit="cover"
                              objectPosition="center"
                              image={tile.image.childImageSharp.gatsbyImageData}
                              style={{
                                width: '100%',
                                height: '100%'
                              }}
                            />
                          ) : (
                            <S.Tile
                              target="_blank"
                              title={tile.title}
                              href={tile.href}
                              rel="noopener noreferrer"
                            >
                              {tile.title}
                              <Vector name="old-arrow-top-right" />
                            </S.Tile>
                          )}
                        </S.MosaicItem>
                      </AnimatePresence>
                    )}
                  </S.SkeletonMosaic>
                )
              })}
            </S.Mosaic>
            {/* <S.Mosaic>
              {mosaics[positionMosaic].nodes.map((item, index) => {
                if (!item.href) {
                  return (
                    <S.SkeletonTite key={item.id}>
                      <S.Tile
                        initial="hidden"
                        whileInView="visible"
                        transition={{
                          duration: 0.5,
                          delay: 0.1 * index
                        }}
                        variants={{
                          visible: { opacity: 1 },
                          hidden: { opacity: 0 }
                        }}
                      >
                        <GatsbyImage
                          alt="Colaboradores Superlógica"
                          image={item.image.childImageSharp.gatsbyImageData}
                          loading="eager"
                          objectFit="cover"
                          objectPosition="top"
                          style={{
                            width: '100%',
                            height: '100%'
                          }}
                        />
                      </S.Tile>
                    </S.SkeletonTite>
                  )
                } else {
                  return (
                    <S.SkeletonTite key={item.id}>
                      <S.Tile
                        target="_blank"
                        title={item.title}
                        rel="noopener noreferrer"
                        href={item.href}
                      >
                        {item.title}
                        <Vector name="old-arrow-top-right" />
                      </S.Tile>
                    </S.SkeletonTite>
                  )
                }
              })}
            </S.Mosaic> */}
          </Col>
        </Row>
      </Container>
    </S.SectionDiversity>
  )
}

export default SectionDiversity
