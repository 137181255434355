import React from 'react'
import { Ribbon } from 'components/app'
import * as S from './View.styled'

const SectionRibbon = () => (
  <S.SectionRibbon id="vemSerSuper">
    <S.Content>
      <Ribbon angle={-3} duration="1000s" />
    </S.Content>
  </S.SectionRibbon>
)

export default SectionRibbon
