import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Block, Title } from '@superlogica/design-ui'
import { FeatureCard } from 'components/app'
import { VectorsProps } from 'svg/vectors'
import { graphql, useStaticQuery } from 'gatsby'

import * as S from './View.styled'

interface BenefitsDataProps {
  allBenefitsJson: {
    nodes: {
      order: number
      category: string
      color: Color
      icon: VectorsProps
      title: string
      badge: VectorsProps
      body: string
    }[]
  }
}

const SectionBenefits = () => {
  const {
    allBenefitsJson: { nodes: benefits }
  } = useStaticQuery<BenefitsDataProps>(
    graphql`
      query {
        allBenefitsJson(
          filter: { category: { eq: "home" } }
          sort: { fields: order }
        ) {
          nodes {
            order
            category
            color
            icon
            title
            badge
            body
          }
        }
      }
    `
  )
  return (
    <S.SectionBenefits>
      <Container>
        <Title
          font="heading"
          size="display03"
          color="sonic500"
          secondColor="babar900"
          marginBottom={48}
        >
          Super <span>benefícios</span>
        </Title>
        <Block
          tag="article"
          display="grid"
          gap={{ xxxs: 32, md: 48 }}
          gridTemplateColumns={{ xxxs: '1fr', md: '1fr 1fr' }}
        >
          {benefits.map(({ order, title, body, color, badge, icon }) => (
            <FeatureCard
              key={order}
              color={color}
              orientation="horizontal"
              title={title}
              badge={badge}
              body={body}
              vector={icon}
            />
          ))}
        </Block>
      </Container>
    </S.SectionBenefits>
  )
}

export default SectionBenefits
