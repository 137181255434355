import React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Title, Text } from '@superlogica/design-ui'
import { Link, PulseButton } from 'components/helpers'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { GalleryNodeData } from 'fragments'
import { AnimatePresence } from 'framer-motion'
import useMosaic from 'hooks/use-mosaic'

import * as S from './View.styled'

const SectionIntro = () => {
  const data = useStaticQuery<GalleryNodeData>(graphql`
    query {
      allPhotosJson(
        filter: {
          category: {
            in: ["mosaic-intro-1", "mosaic-intro-2", "mosaic-intro-3"]
          }
        }
      ) {
        nodes {
          ...GalleryData
        }
      }
    }
  `)

  const { groups, gallery } = useMosaic(data)

  return (
    <S.SectionIntro>
      <Container>
        <S.Content>
          <Title
            font="heading"
            size="extraLarge"
            color="babar900"
            secondColor="sonic500"
            marginBottom={32}
          >
            <span>Juntos</span> transformamos o mundo
          </Title>
          <Text color="babar900" size="body04" lineHeight={1.4}>
            Nosso ambiente é movido pela inclusão e respeito, nos unimos em
            torno de um grande propósito:{' '}
            <strong>transformar o mundo através do empreendedorismo.</strong>
          </Text>
        </S.Content>
      </Container>
      <S.InnerSection>
        <S.MosaicWrapper>
          <S.ButtonWrapper>
            <PulseButton
              to="/#vemSerSuper"
              title="Vem ser Super"
              color="sonic500"
            >
              Vem ser
              <br /> Super
            </PulseButton>
          </S.ButtonWrapper>
          <S.Mosaic>
            {groups.map((i) => {
              const photo = gallery[i]

              return (
                <S.SkeletonMosaic key={i}>
                  {!!photo && (
                    <AnimatePresence>
                      <S.MosaicItem
                        key={`photo-${photo.id}`}
                        transition={{ duration: 2 }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                      >
                        <GatsbyImage
                          alt="Colaborador Superlógica"
                          objectFit="cover"
                          objectPosition="center"
                          image={photo.image.childImageSharp.gatsbyImageData}
                          style={{
                            width: '100%',
                            height: '100%'
                          }}
                        />
                      </S.MosaicItem>
                    </AnimatePresence>
                  )}
                </S.SkeletonMosaic>
              )
            })}
          </S.Mosaic>
        </S.MosaicWrapper>
      </S.InnerSection>
    </S.SectionIntro>
  )
}

export default SectionIntro
