import styled, { css, keyframes } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rem } from 'polished'
import { motion } from 'framer-motion'

const scaleButton = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const Tile = styled.a``

export const Mosaic = styled.div``

export const MosaicWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding-top: ${rem(40)};

  ${media.lessThan('md')} {
    padding-top: 0;
  }
`

export const SkeletonMosaic = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper50)};
  `}
`

export const MosaicItem = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  padding: 0;
  margin: 0;

  .vector {
    align-self: flex-end;
  }

  .gatsby-image-wrapper {
    * {
      border-radius: 4px;
    }
  }

  ${Tile} {
    width: 100%;
    height: 100%;
    padding: ${rem(24)};
    font-size: ${rem(16)};
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${({ theme: { colors } }) => css`
      color: ${parseColor(colors.babar0)};

      &:hover {
        background-color: ${parseColor(colors.sonic600)};
      }
    `};
  }
`

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20%;
  left: -80px;
  z-index: 10;

  ${media.greaterThan('md')} {
    animation: ${scaleButton} 1s 1.2s cubic-bezier(0.5, 0, 0.1, 1) both;
  }

  ${media.lessThan('sm')} {
    bottom: auto;
    left: auto;
    top: -80px;
    right: 80px;
  }

  ${media.lessThan('xs')} {
    top: -50px;
    right: 24px;
  }
`
export const Content = styled.div``

export const AvatarWrapper = styled(motion.div)``

export const InnerSection = styled.div``

export const InnerImage = styled.div``

export const SectionIntro = styled.section`
  display: flex;
  overflow: hidden;
  align-items: center;
  position: relative;
  height: 100vh;

  ${Content} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    position: relative;
    z-index: 3;
    padding: 60px 0;
  }

  ${InnerSection} {
    width: calc(45vw + ${rem(100)});
    height: 100%;
    position: absolute;
    top: 0;
    right: ${rem(-100)};
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  ${Mosaic} {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: ${rem(12)};
  }

  ${SkeletonMosaic} {
    height: 320px;
    grid-column: span 6;

    &:nth-child(3n + 1) {
      grid-column: span 4;
    }
  }

  &:after {
    content: '';
    width: 20%;
    height: calc(100% - ${rem(120)});
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: ${rem(80)};
  }

  ${({ theme: { colors } }) => css`
    &:after {
      background-color: ${parseColor(colors.trooper25)};
    }
  `}

  ${media.lessThan('md')} {
    ${Content} {
      max-width: 440px;
    }
  }

  ${media.lessThan('sm')} {
    flex-direction: column;
    height: auto;

    ${SkeletonMosaic} {
      height: 168px;
    }

    ${InnerSection} {
      position: initial;
      width: calc(100vw + ${rem(10)});
    }

    ${Content} {
      padding: 100px 0 60px;
    }
  }
  ${media.lessThan('sm')} {
    &:after {
      display: none;
    }
  }
`

export const SectionRibbon = styled.section`
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    width: 110vw;
    height: 70px;
    top: 0;
    bottom: 0;
    left: -20px;
    margin: auto 0;
    transform: rotate(3.5deg);
  }

  &:after {
    width: 20%;
    height: 100px;
    top: 0;
    right: 0;
  }

  ${Content} {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  ${({ theme: { colors } }) => css`
    &:after,
    &:before {
      background-color: ${parseColor(colors.trooper25)};
    }
  `}

  ${media.lessThan('sm')} {
    &:after {
      display: none;
    }
  }
  ${media.lessThan('xs')} {
    height: 150px;

    &:before {
      transform: rotate(8deg);
    }
  }
`

export const SectionAreas = styled.section`
  position: relative;
  padding: ${rem(80)} 0;

  .break-title {
    font-size: ${rem(48)};
    line-height: ${rem(56)};
    font-weight: 500;
    max-width: 450px;
  }

  ${AvatarWrapper} {
    width: ${rem(52)};
    height: ${rem(52)};
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid;

    &:nth-child(1) {
      z-index: 3;
    }

    &:nth-child(2) {
      margin-left: -10px;
      z-index: 2;
    }

    &:nth-child(3) {
      margin-left: -10px;
      z-index: 1;
    }

    &:last-child {
      width: ${rem(64)};
      height: ${rem(64)};
      font-weight: 700;
      text-transform: uppercase;
      font-size: ${rem(14)};
      margin-left: -10px;
      border: 3px solid;
      z-index: 4;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${({ theme: { colors } }) => css`
    ${AvatarWrapper} {
      border-color: ${parseColor(colors.babar0)};
      &:last-child {
        color: ${parseColor(colors.babar0)};
        background-color: ${parseColor(colors.sonic500)};
        border-color: ${parseColor(colors.babar0)};
      }
    }
  `};

  ${media.lessThan('sm')} {
    padding: ${rem(20)} 0 ${rem(60)};

    .break-title {
      font-size: inherit;
      line-height: inherit;
      max-width: 100%;
    }
  }
`

export const SectionDiversity = styled.section`
  padding: ${rem(80)} 0;

  ${Mosaic} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: ${rem(12)};

    ${media.lessThan('xxs')} {
      grid-template-columns: 1fr;
    }
  }

  ${SkeletonMosaic} {
    height: 140px;

    &:nth-child(1),
    &:nth-child(5) {
      grid-column: span 3;
    }

    &:nth-child(2),
    &:nth-child(6) {
      grid-column: span 5;
    }

    &:nth-child(3) {
      grid-column: span 4;
    }

    &:nth-child(4) {
      grid-column: span 4;
    }

    &:nth-child(7) {
      grid-column: span 5;
    }

    &:nth-child(8) {
      grid-column: span 7;
    }

    ${media.lessThan('xxs')} {
      &:nth-child(3),
      &:nth-child(4) {
        grid-column: span 5;
      }
    }

    ${({ theme: { colors } }) => css`
      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(8) {
        background-color: ${parseColor(colors.trooper75)};
      }

      &:nth-child(2),
      &:nth-child(4) {
        background-color: ${parseColor(colors.sonic500)};
      }
      &:nth-child(3),
      &:nth-child(7) {
        background-color: ${parseColor(colors.sonic600)};
      }
      &:nth-child(6) {
        background-color: ${parseColor(colors.sonic400)};
      }
    `};
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper25)};
  `}

  ${media.lessThan('sm')} {
    padding: ${rem(60)} 0;
  }

  ${media.lessThan('xxs')} {
    ${SkeletonMosaic} {
      grid-column: auto;

      &:nth-child(1),
      &:nth-child(5),
      &:nth-child(8) {
        display: none;
      }
    }
  }
`

export const SectionTestimonies = styled.section`
  padding: ${rem(80)} 0 ${rem(120)};
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .gatsby-image-wrapper {
    border-radius: 50%;
    overflow: hidden;
  }

  .sdl-carousel {
    padding-bottom: 60px;

    .swiper {
      overflow: visible;
    }

    .swiper-pagination {
      bottom: -60px;
    }

    .swiper-slide {
      height: auto;
    }
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar0)};

    &:after {
      background-image: linear-gradient(
        to right,
        ${parseColor(colors.sonic500)} calc(66% + 32px),
        ${parseColor(colors.sonic600)} 0
      );
    }
  `};

  ${media.lessThan('md')} {
    padding: ${rem(60)} 0 ${rem(120)};
  }
`

export const SectionBenefits = styled.section`
  padding: ${rem(80)} 0;

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.trooper25)};
  `};

  ${media.lessThan('sm')} {
    padding: ${rem(60)} 0;
  }
`

export const SectionTech = styled.section`
  position: relative;
  scroll-margin-top: 60px;

  ${AvatarWrapper} {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid;

    .gatsby-image-wrapper {
      border-radius: 50%;
      overflow: hidden;
    }

    &:nth-child(1) {
      z-index: 5;
    }
    &:nth-child(2) {
      z-index: 4;
    }
    &:nth-child(3) {
      z-index: 3;
    }
    &:nth-child(4) {
      z-index: 2;
    }
    &:nth-child(5) {
      z-index: 1;
    }

    &:not(:first-child) {
      margin-left: -12px;
    }
  }

  ${InnerSection} {
    height: 100%;
    position: relative;
    padding-left: ${rem(16)};

    &:before,
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
    }

    &:before {
      width: 140px;
      right: 0;
      border-top-left-radius: ${rem(80)};
    }
    &:after {
      width: 100vw;
      right: -100vw;
    }
  }

  .btn-tech {
    align-self: flex-start;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.babar900)};

    ${InnerSection} {
      &:before,
      &:after {
        background-color: ${parseColor(colors.babar800)};
      }
    }

    ${AvatarWrapper} {
      border-color: ${parseColor(colors.babar900)};
    }
  `};

  ${media.greaterThan('sm')} {
    .btn-tech {
      align-self: center;
    }
  }

  ${media.lessThan('sm')} {
    ${InnerSection} {
      padding-left: 0;

      .list-tech {
        flex-direction: column;
      }
      &:before {
        width: 70%;
      }
    }
  }
`

export const SectionCta = styled.section`
  overflow: hidden;
  position: relative;

  ${InnerSection} {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 80px 0;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      top: 0;
    }

    &:before {
      width: 140px;
      right: 0;
      border-bottom-left-radius: ${rem(80)};
    }
    &:after {
      width: 100vw;
      right: -100vw;
    }
  }

  ${InnerImage} {
    overflow: hidden;
    border-radius: ${rem(8)};
    position: relative;
    z-index: 2;
  }

  ${({ theme: { colors } }) => css`
    background-color: ${parseColor(colors.sonic500)};

    &:after {
      background-color: ${parseColor(colors.sonic400)};
    }

    ${InnerSection} {
      &:before,
      &:after {
        background-color: ${parseColor(colors.sonic400)};
      }
    }
  `};

  ${media.lessThan('sm')} {
    ${InnerSection} {
      padding: 60px 0;
      &:before {
        width: 60px;
        border-bottom-left-radius: 0;
        border-top-left-radius: ${rem(40)};
      }
    }
  }
`

export const SectionFaq = styled.section`
  scroll-margin-top: 60px;
`
